<template>
  <div class="inside-page-content">
    <div class="paper all-center">
      <div class="top-row">
        <div class="top-info">
            {{detailData.organizationName}}
        </div>
      </div>
      <!--分割线-->
      <div class="divider"></div>

      <div class="detail-content">
        <div class="content-item">
          <div class="content-title">关于我们</div>
          <div class="content-text">{{detailData.organizationIntroduction}}</div>
        </div>
        <div class="content-item">
          <div class="content-title">服务内容</div>
          <div class="content-text">{{detailData.serviceContent}}</div>
        </div>
        <div class="content-item">
          <div class="content-title">联系我们</div>
          <div class="content-text">联系人 {{detailData.contactName}}  联系方式 {{detailData.contactPhone}} 联系地址 {{detailData.contactAddress}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TopFilter from './components/topFilter.vue'
export default {
  name: 'organizationDetail',
  components: {
    TopFilter
  },
  data() {
    return {
      id:null,
      detailData:{}
    }
  },
  computed:{
  },
  created(options) {
    this.id = this.$route.query?.id
    this.getDetail()
  },
  mounted() {

  },
  methods: {
    getDetail() {
      this.$api.intellectualProperty.gizDetail(this.id).then((res) => {
        this.detailData = res.data.data
      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">

.top-row {
  height: 130px;
  padding: 0 80px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-around;
  .top-info{
    flex: 1;
    font-weight: 700;
  }
}
.desc {
  display: flex;
  flex-direction: row;
  padding: 20px 80px;
  justify-content: space-around;
  .desc-item{
    display: flex;
    flex-direction: column;
    width: 20%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    .info-text{
      padding-top: 10px;
      font-size: 14px;
      font-weight: 200;
    }
  }
}
.divider{
  width: 95%;
  margin: 20px auto;
  border-bottom: 1px dashed grey;
}
.detail-content{
  margin: 20px 80px;
  .content-item{
    margin: 40px 0;
    .content-title{
      border-left: 5px solid blue;
      padding-left: 10px;
      font-weight: 700;
    }
    .content-text{
      margin-top: 10px;
      font-size: 14px;
    }
  }

}

</style>
